import { Box, Button, Card, CardContent, Divider, InputLabel, Typography } from '@mui/material';
import ClearFleetForm, { Field, ReadOnlyField, SelectField } from 'core/components/ClearFleetForm';
import FormCheckboxField from 'core/components/FormCheckboxField';
import ProgressPercentage from 'core/components/ProgressPercentage';
import { useAPI } from 'core/hooks';
import {
	DateFormat,
	dayjsFromObject,
	formatDateRange,
	isEqual,
	numberFormat,
	phoneNumberFormat,
} from 'core/services/intl';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import FleetsService from 'modules/irp/modules/fleets/api/FleetsService';
import { useCarrier } from 'modules/irp/modules/supplements/providers/CarrierProvider';
import { useClient } from 'modules/irp/modules/supplements/providers/ClientProvider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Address, AddressFields, AddressValidationSchema } from 'types/Address';
import { Contact, contactsAreEqual, ContactValidationSchema } from 'types/Contact';
import Date, { DateValidationSchema, dayjsToDate } from 'types/Date';
import Fleet, { FleetActions, FleetFields, MileageType } from 'types/Fleet';
import LookupValue, { LookupValueValidationSchema } from 'types/LookupValue';
import Program from 'types/Program';
import { State, stateCountyRequired, stateSelectOptions, statesWithDivider } from 'types/State';
import * as Yup from 'yup';

interface FleetFormValues {
	useIrpContact: boolean;
	contact: Contact;
	addresses: {
		mailing: Address | null;
	};
	type: LookupValue | null;
	commodityClass: LookupValue | null;
	wyomingIntrastate: boolean;
	trailerOnly: boolean;
	actualDistance: boolean;
	iftaMileage: boolean;
	startDate: Date | null;
	endDate: Date | null;
}

export interface FleetFormProps {
	fleet: Fleet | null;
	onFormik: (formik: ReturnType<typeof useFormik<FleetFormValues>>) => void;
	onSubmit: (data: FleetFields, actions: FleetActions) => Promise<void> | void;
	irpContact?: Contact;
	hideProgress?: boolean;
	editEffectiveDate?: boolean;
	editExpirationDate?: boolean;
	editMileage?: boolean;
	disableTrailerOnly?: boolean;
}

export default function FleetForm({
	fleet,
	onFormik,
	onSubmit,
	irpContact,
	hideProgress,
	editEffectiveDate,
	editExpirationDate,
	editMileage,
	disableTrailerOnly,
}: FleetFormProps) {
	// Hooks
	const { t } = useTranslation('irp/fleets');
	const { states } = useClient();
	const { carrier } = useCarrier();

	// Services
	const fleetsService = useAPI(FleetsService);

	// State
	const [loading, setLoading] = useState(false);
	const [fleetTypes, setFleetTypes] = useState<LookupValue[]>([]);
	const [commodityClasses, setCommodityClasses] = useState<LookupValue[]>([]);
	const [hasIFTAMileage, setHasIFTAMileage] = useState<boolean>(false);

	// Computed
	const hasIfta = carrier?.programStatuses.some(({ program }) => program === Program.IFTA);

	const validationSchema = Yup.object<FleetFormValues>().shape({
		contact: Yup.object().shape(ContactValidationSchema({ t })),
		addresses: Yup.object().shape({
			mailing: Yup.object().shape(AddressValidationSchema({ t })).nullable(),
		}),
		type: Yup.object(LookupValueValidationSchema).required(t('data.validation.required', { ns: 'core' })),
		commodityClass: Yup.object(LookupValueValidationSchema).required(t('data.validation.required', { ns: 'core' })),
		wyomingIntrastate: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		trailerOnly: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		iftaMileage: Yup.boolean().required(t('data.validation.required', { ns: 'core' })),
		actualDistance: Yup.boolean().strip(), // Don't send this to the API, pass it as actions
		startDate: DateValidationSchema.required(t('data.validation.required', { ns: 'core' }))
			.test(
				'startDateFirstOfMonth',
				t('details.validations.startDateFirstOfMonth'),
				(v) => dayjsFromObject(v as Date)?.date() === 1,
			)
			.test(
				'startDateFuture',
				t('data.validation.datepicker.maxDate', { ns: 'core', maxDate: dayjs().add(1, 'month').format(DateFormat) }),
				(v) => {
					// Don't validate if not editing effective date
					if (!editEffectiveDate) return true;

					const date = dayjsFromObject(v as Date);
					return date && date.isBefore(dayjs().add(1, 'month'));
				},
			),
		endDate: DateValidationSchema.required(t('data.validation.required', { ns: 'core' })).when(
			['startDate'],
			([startDate], schema) => {
				// Don't validate if not editing expiration date
				if (!editExpirationDate) return schema;

				const minDate = dayjsFromObject(startDate);
				const maxDate = minDate?.add(1, 'year').subtract(1, 'day');
				return schema
					.test('endDateLastOfMonth', t('details.validations.endDateLastOfMonth'), (v) => {
						const d = dayjsFromObject(v as Date);
						return d?.date() === d?.endOf('month').date();
					})
					.test(
						'afterStartDate',
						t('data.validation.datepicker.minDate', { ns: 'core', minDate: minDate?.format(DateFormat) }),
						(v) => {
							const date = dayjsFromObject(v as Date);
							return date && !date.isBefore(minDate);
						},
					)
					.test(
						'max12months',
						t('data.validation.datepicker.maxDate', { ns: 'core', maxDate: maxDate?.format(DateFormat) }),
						(v) => {
							const date = dayjsFromObject(v as Date);
							return date && !date.isAfter(maxDate);
						},
					);
			},
		),
	});

	// Form
	const formik = useFormik<FleetFormValues>({
		initialValues: {
			useIrpContact: isEqual(fleet?.contact, irpContact),
			contact: {
				name: fleet?.contact?.name || '',
				phone: fleet?.contact?.phone ? phoneNumberFormat(fleet.contact.phone) : '',
				phoneExtension: fleet?.contact?.phoneExtension || null,
				email: fleet?.contact?.email || '',
				fax: fleet?.contact?.fax ? phoneNumberFormat(fleet.contact.fax) : '',
			},
			addresses: {
				mailing: fleet?.addresses.mailing || null,
			},
			type: fleet?.type || null,
			commodityClass: fleet?.commodityClass || null,
			wyomingIntrastate: fleet?.wyomingIntrastate || false,
			trailerOnly: fleet?.trailerOnly || false,
			iftaMileage: fleet?.iftaMileage || false,
			actualDistance: fleet?.mileageType === MileageType.Actual,
			startDate: fleet?.startDate || null,
			endDate: fleet?.endDate || null,
		},
		validationSchema,
		onReset: () => {
			formik.validateForm();
		},
		onSubmit: async (data) => {
			if (!fleet) return undefined;

			const fields: FleetFields = validationSchema.cast(data, { context: data, stripUnknown: true });
			const mileageType: MileageType = (() => {
				if (data.iftaMileage) return MileageType.IFTA;
				if (data.actualDistance) return MileageType.Actual;
				return MileageType.Estimated;
			})();

			const actions = { mileageType };

			// CLEAR-1754: Only send mileageType if actualDistance or iftaMileage have changed
			const hasChanged = fleet.mileageType !== actions.mileageType || fleet.iftaMileage !== data.iftaMileage;

			// CLEAR-1905: Address can be removed
			if (data.addresses.mailing === null) {
				fields.addresses = { mailing: {} as AddressFields }; // Clear address
			}

			return onSubmit(fields, hasChanged ? actions : {});
		},
	});

	const contactFormFields: Field<FleetFormValues>[] = [
		{
			type: formik.values.useIrpContact ? 'readonly' : 'text',
			name: 'contact.name',
			label: t('fleet.contact.name', { ns: 'data' }),
			max: 100,
			required: true,
			getValue: (v) => v.contact?.name || '',
		},
		{
			type: formik.values.useIrpContact ? 'readonly' : 'text',
			name: 'contact.phone',
			label: t('fleet.contact.phone', { ns: 'data' }),
			required: true,
			max: 14,
			getValue: (v) => phoneNumberFormat(v.contact?.phone || ''),
		},
		{
			type: formik.values.useIrpContact ? 'readonly' : 'number',
			name: 'contact.phoneExtension',
			label: t('fleet.contact.phoneExt', { ns: 'data' }),
			getValue: (v) => (v.contact?.phoneExtension ? String(v.contact.phoneExtension) : ''),
		},
		{
			type: formik.values.useIrpContact ? 'readonly' : 'text',
			name: 'contact.email',
			label: t('fleet.contact.email', { ns: 'data' }),
			required: true,
			getValue: (v) => v.contact?.email || '',
		},
		{
			type: formik.values.useIrpContact ? 'readonly' : 'text',
			name: 'contact.fax',
			label: t('fleet.contact.fax', { ns: 'data' }),
			max: 14,
			getValue: (v) => phoneNumberFormat(v.contact?.fax || ''),
		},
		{
			type: 'component',
			name: 'addressDisplay',
			component: fleet?.addresses.business ? (
				<Box>
					<InputLabel sx={{ mb: 1 }}>{t('fleet.contact.address', { ns: 'data' })}</InputLabel>
					<Typography variant="body2">{fleet?.addresses.business.line1}</Typography>
					<Typography variant="body2">{fleet?.addresses.business.line2}</Typography>
					<Typography variant="body2">
						{fleet?.addresses.business.city}, {fleet?.addresses.business.state?.code}{' '}
						{fleet?.addresses.business.postalCode}, {fleet?.addresses.business.country}
					</Typography>
				</Box>
			) : null,
		},
	];

	const mailingAddressFields: Field<FleetFormValues>[] = [
		{
			type: 'text',
			name: 'addresses.mailing.line1',
			label: t('data.fields.street1', { ns: 'core' }),
			required: true,
			max: 100,
			getValue: (v) => v.addresses.mailing?.line1 || '',
		},
		{
			type: 'text',
			name: 'addresses.mailing.line2',
			label: t('data.fields.street2', { ns: 'core' }),
			max: 100,
			getValue: (v) => v.addresses.mailing?.line2 || '',
		},
		{
			type: 'text',
			name: 'addresses.mailing.county',
			label: t('data.fields.county', { ns: 'core' }),
			required: stateCountyRequired(formik.values.addresses.mailing?.state?.code || ''),
			getValue: (v) => v.addresses.mailing?.county || '',
		},
		{
			type: 'text',
			name: 'addresses.mailing.city',
			label: t('data.fields.city', { ns: 'core' }),
			required: true,
			max: 50,
			getValue: (v) => v.addresses.mailing?.city || '',
		},
		{
			type: 'select',
			name: 'addresses.mailing.state',
			label: t('data.fields.state', { ns: 'core' }),
			required: true,
			options: statesWithDivider(states),
			getValue: (v) => v.addresses.mailing?.state || null,
			selectProps: {
				...stateSelectOptions,
				onChange: () => {
					setTimeout(() => {
						formik.validateField('addresses.mailing.county');
					}, 1);
				},
			},
		} as SelectField<FleetFormValues, State>,
		{
			type: 'text',
			name: 'addresses.mailing.postalCode',
			label: t('data.fields.zip', { ns: 'core' }),
			required: true,
			max: 10,
			getValue: (v) => v.addresses.mailing?.postalCode || '',
		},
	];

	const typeField: ReadOnlyField<FleetFormValues> | SelectField<FleetFormValues, LookupValue> = (() => {
		const common = {
			name: 'type',
			label: t('fleet.type', { ns: 'data' }),
			required: true,
		};

		return {
			...common,
			type: 'select',
			disabled: loading,
			getValue: (v) => v.type || null,
			options: fleetTypes,
			selectProps: {
				getOptionKey: (v) => v.id,
				getOptionLabel: (v) => `${v.code} - ${v.displayName}`,
			},
		};
	})();

	const commodityClassField: ReadOnlyField<FleetFormValues> | SelectField<FleetFormValues, LookupValue> = (() => {
		const common = {
			name: 'commodityClass',
			label: t('fleet.commodityClass', { ns: 'data' }),
			required: true,
		};

		if (commodityClasses.length <= 1) {
			return {
				...common,
				type: 'readonly',
				getValue: () => (commodityClasses && commodityClasses[0]?.displayName) || '',
			};
		}

		return {
			...common,
			type: 'select',
			disabled: loading,
			getValue: (v) => v.commodityClass || null,
			options: commodityClasses,
			selectProps: {
				getOptionKey: (v) => v.id,
				getOptionLabel: (v) => v.displayName,
			},
		};
	})();

	const wyomingIntrastateField: Field<FleetFormValues> = {
		type: 'radio',
		name: 'wyomingIntrastate',
		label: t('fleet.wyomingIntrastate.prompt', { ns: 'data' }),
		options: [
			{ label: t('data.yes', { ns: 'core' }), value: 'true' },
			{ label: t('data.no', { ns: 'core' }), value: 'false' },
		],
		getValue: (v) => String(v.wyomingIntrastate),
	};

	const actualMileageField: Field<FleetFormValues> = {
		type: 'radio',
		name: 'actualDistance',
		label: t('fleet.actualDistance.prompt', { ns: 'data' }),
		tooltip: t(!editMileage ? 'fleet.distance.non-editable' : 'fleet.actualDistance.tooltip', { ns: 'data' }),
		disabled: !editMileage,
		options: [
			{ label: t('data.yes', { ns: 'core' }), value: 'true' },
			{ label: t('data.no', { ns: 'core' }), value: 'false' },
		],
		getValue: (v) => String(v.actualDistance),
	};

	const iftaMileageField: Field<FleetFormValues> = {
		type: 'radio',
		name: 'iftaMileage',
		label: t('fleet.iftaDistance.prompt', { ns: 'data' }),
		options: [
			{ label: t('data.yes', { ns: 'core' }), value: 'true' },
			{ label: t('data.no', { ns: 'core' }), value: 'false' },
		],
		getValue: (v) => String(v.iftaMileage),
	};

	const startDateField: Field<FleetFormValues> = editEffectiveDate
		? {
				type: 'date',
				name: 'startDate',
				label: t('fleet.startDate', { ns: 'data' }),
				required: true,
				getValue: (v) => {
					const date = dayjsFromObject(v.startDate);
					if (!date) return null;

					return date.set('date', 1);
				},
				datePickerProps: {
					referenceDate: dayjs().set('date', 1),
					views: ['year', 'month'],
					openTo: 'month',
					format: DateFormat,
					minDate: dayjs({ year: 2000, month: 1, day: 1 }),
					maxDate: dayjs().add(1, 'month'),
					shouldDisableDate: (d) => d.date() !== 1,
				},
			}
		: {
				type: 'readonly',
				name: 'startDate',
				label: t('fleet.startDate', { ns: 'data' }),
				getValue: (v) => {
					const date = dayjsFromObject(v.startDate);
					if (!date) return '';

					return date.format(DateFormat);
				},
			};

	const endDateField: Field<FleetFormValues> = editExpirationDate
		? {
				type: 'date',
				name: 'endDate',
				label: t('fleet.endDate', { ns: 'data' }),
				required: true,
				getValue: (v) => {
					const date = dayjsFromObject(v.endDate);
					if (!date) return null;

					return date.endOf('month');
				},
				datePickerProps: {
					referenceDate: dayjs().endOf('month'),
					views: ['year', 'month'],
					openTo: 'month',
					format: DateFormat,
					minDate: dayjsFromObject(formik.values.startDate),
					maxDate: dayjsFromObject(formik.values.startDate)?.add(1, 'year').subtract(1, 'day'),
					shouldDisableDate: (d) => d.date() !== d.endOf('month').date(),
				},
			}
		: {
				type: 'readonly',
				name: 'endDate',
				label: t('fleet.endDate', { ns: 'data' }),
				getValue: (v) => {
					const date = dayjsFromObject(v.endDate);
					if (!date) return '';

					return date.format(DateFormat);
				},
			};

	const detailsFormFields: Field<FleetFormValues>[] = [
		{
			type: 'number',
			name: 'number',
			label: t('fleet.number', { ns: 'data' }),
			getValue: () => numberFormat(fleet?.number || 0),
			inputProps: {
				readOnly: true,
			},
		},
		startDateField,
		endDateField,
		typeField,
		commodityClassField,
		{
			type: 'text',
			name: 'reportingPeriod',
			label: t('fleet.reportingPeriod', { ns: 'data' }),
			getValue: () =>
				fleet?.mileageReportingPeriod
					? formatDateRange(fleet.mileageReportingPeriod.startDate, fleet.mileageReportingPeriod.endDate)
					: '-',
			inputProps: {
				readOnly: true,
			},
		},
		{
			type: 'radio',
			name: 'trailerOnly',
			label: t('fleet.trailerOnly.prompt', { ns: 'data' }),
			disabled: disableTrailerOnly,
			tooltip: disableTrailerOnly ? t('fleet.trailerOnly.non-editable', { ns: 'data' }) : undefined,
			options: [
				{ label: t('data.yes', { ns: 'core' }), value: 'true' },
				{ label: t('data.no', { ns: 'core' }), value: 'false' },
			],
			getValue: (v) => String(v.trailerOnly),
		},
		...(formik.values.trailerOnly ? [] : [wyomingIntrastateField, actualMileageField]),
		...(hasIfta && fleet?.number === 1 && formik.values.actualDistance && hasIFTAMileage ? [iftaMileageField] : []),
	];

	const handleIrpContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!irpContact) return;

		// Set checkbox
		formik.setFieldValue('useIrpContact', e.target.checked);

		// Set contact fields from IRP contact
		if (e.target.checked) {
			const contact: Contact = {
				name: irpContact?.name || '',
				phone: irpContact?.phone ? phoneNumberFormat(irpContact.phone) : '',
				phoneExtension: irpContact?.phoneExtension || null,
				email: irpContact?.email || '',
				fax: irpContact?.fax ? phoneNumberFormat(irpContact.fax) : '',
			};
			formik.setFieldValue('contact', contact);
		}
	};

	// Pass form handler up to parent
	useEffect(() => {
		onFormik(formik);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onFormik]);

	// Get available fleet types from API
	useEffect(() => {
		setLoading(true);
		fleetsService
			.listTypes()
			.then(setFleetTypes)
			.finally(() => setLoading(false));
	}, [fleetsService]);

	// Get commodity types appropriate to fleet type
	useEffect(() => {
		if (!formik.values.type) return;

		setLoading(true);
		fleetsService
			.listCommodityClasses(formik.values.type.id)
			.then((data) => {
				setCommodityClasses(data);

				if (formik.values.type === fleet?.type) {
					// Set commodity class if fleet type matches
					formik.setFieldValue('commodityClass', fleet?.commodityClass);
				} else if (data.length === 1) {
					// Set first commodity class if only one available
					formik.setFieldValue('commodityClass', data[0]);
				} else {
					// Clear commodity class if not
					formik.setFieldValue('commodityClass', null);
				}
			})
			.finally(() => setLoading(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.type, fleetsService]);

	// When trailer only is selected
	useEffect(() => {
		if (!formik.values.trailerOnly) return;

		// Trailer-only, clear wyomingIntrastate, actualDistance, and iftaMileage
		formik.setFieldValue('wyomingIntrastate', false);
		formik.setFieldValue('actualDistance', true);
		formik.setFieldValue('iftaMileage', false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.trailerOnly]);

	// When IRP contact loads, set form values
	useEffect(() => {
		if (!irpContact) return;

		formik.setFieldValue('useIrpContact', contactsAreEqual(fleet?.contact, irpContact));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [irpContact]);

	// Check if fleet has IFTA mileage
	useEffect(() => {
		if (!fleet?.key) return;
		setLoading(true);

		fleetsService
			.getMileage(fleet.key, MileageType.IFTA)
			.then((res) => setHasIFTAMileage(res.length > 0))
			.finally(() => setLoading(false));
	}, [fleet?.key, fleetsService]);

	// When fleet start date is changed, update the end date
	useEffect(() => {
		if (!formik.values.startDate || !editExpirationDate || formik.values.startDate === formik.initialValues.startDate)
			return;

		formik.setFieldValue(
			'endDate',
			dayjsToDate(dayjsFromObject(formik.values.startDate)?.add(1, 'year').subtract(1, 'day')),
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.startDate]);

	return (
		<Card>
			<CardContent>
				<Typography variant="h3" gutterBottom mb={hideProgress ? 4 : 2}>
					{t('details.title')}
				</Typography>

				{!hideProgress && <ProgressPercentage progress={33} />}

				<Typography variant="h4" gutterBottom mb={2}>
					{t('details.contact.information')}
				</Typography>

				{irpContact && (
					<Box display="inline-flex" mb={2}>
						<FormCheckboxField
							name="useIrpContact"
							label={t('details.contact.use_irp')}
							checked={formik.values.useIrpContact}
							onChange={handleIrpContactChange}
							slotProps={{
								formControl: { error: !!formik.errors.useIrpContact },
							}}
						/>
					</Box>
				)}

				<ClearFleetForm fields={contactFormFields} form={formik} loading={loading || formik.isSubmitting} />

				{!formik.values.addresses.mailing && (
					<Box display="flex" columnGap={1} sx={{ mt: 3 }}>
						<Button
							variant="outlined"
							onClick={() => formik.setFieldValue('addresses.mailing', { state: null })}
							disabled={formik.isSubmitting}
						>
							{t('buttons.add_mailing_address', { ns: 'core' })}
						</Button>
					</Box>
				)}

				{!!formik.values.addresses.mailing && (
					<>
						<Divider sx={{ my: 3 }} />
						<Typography variant="h4" gutterBottom mb={2}>
							{t('details.mailing_address')}
						</Typography>
						<ClearFleetForm fields={mailingAddressFields} form={formik} loading={formik.isSubmitting} />
						<Button
							sx={{ mt: 3 }}
							color="error"
							variant="outlined"
							onClick={() => formik.setFieldValue('addresses.mailing', null)}
							disabled={formik.isSubmitting}
						>
							{t('buttons.remove_mailing_address', { ns: 'core' })}
						</Button>
					</>
				)}
				<Divider sx={{ my: 3 }} />

				<ClearFleetForm fields={detailsFormFields} form={formik} loading={loading || formik.isSubmitting} />
			</CardContent>
		</Card>
	);
}
