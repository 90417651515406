import { Card, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useToast } from 'core/hooks';
import { DocumentType } from 'modules/documents/types/Document';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import VehicleDocumentationTable, {
	VehicleDocumentationTableType,
} from 'modules/irp/modules/supplements/components/VehicleDocumentationTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import DeleteVehiclePaths from './paths';

export default function DeleteDocumentsStep() {
	// Hooks
	const { supplementKey } = useTypedParams(DeleteVehiclePaths);
	const { t } = useTranslation(['irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();
	const [validDeleteDocuments, setValidDeleteDocuments] = useState<boolean>(false);

	const handleNext = () => {
		if (!validDeleteDocuments) {
			openToast({
				id: 'irp/delete_vehicle/documents',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
			return;
		}
		navigate(DeleteVehiclePaths.Verify.buildPath({ supplementKey }));
	};

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent>
					{/* Deleted Vehicles */}
					<Typography variant="h3">{t('documentation.delete_documentation')}</Typography>
					<VehicleDocumentationTable
						tableType={VehicleDocumentationTableType.Delete}
						documentTypes={[DocumentType.SelfCertification, DocumentType.ClaimForRefund]}
						validator={setValidDeleteDocuments}
					/>
				</CardContent>
			</Card>
			<SupplementStepFooter
				previousPath={DeleteVehiclePaths.Details.buildPath({ supplementKey })}
				nextLabel={t('buttons.next', { ns: 'core' })}
				onNext={handleNext}
			/>
		</Box>
	);
}
