import CheckIcon from '@mui/icons-material/Check';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ActionMenu from 'core/components/ActionMenu';
import StyledDataGrid, { DataGridColumnHeader } from 'core/components/DataGrid';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import { Dayjs } from 'dayjs';
import { getIn, setIn, useFormik } from 'formik';
import DocumentsService from 'modules/irp/modules/supplements/api/DocumentsService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import { SupplementContentSkeleton } from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import AddVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/AddVehicleDialog';
import RemoveVehicleDialog from 'modules/irp/modules/supplements/components/dialogs/RemoveVehicleDialog';
import {
	getFeeCalcTooltipError,
	transferFeeCalcTest,
} from 'modules/irp/modules/supplements/modules/add_vehicle/types/FormValidations';
import EditVehicleDialog from 'modules/irp/modules/supplements/modules/transfer_vehicle/components/EditVehicleDialog';
import TransferVehiclePaths from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/paths';
import VehiclesService from 'modules/irp/modules/vehicles/api/VehiclesService';
import Vehicle, {
	VehicleFeeCalculationDate,
	VehicleFields,
	VehicleNew,
	getFeeCalculationDate,
} from 'modules/irp/modules/vehicles/types/Vehicle';
import WeightGroupsService from 'modules/irp/modules/weight_groups/api/WeightGroupsService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { DateValidationSchema, DateValidations, MIN_DATE, dayjsToDate } from 'types/Date';
import LookupValue, { LookupValueValidationSchema, getLookupValueByCode } from 'types/LookupValue';
import { WeightGroupType } from 'types/WeightGroup';
import * as Yup from 'yup';

export interface VehicleTransfer extends Vehicle {
	transferVehicle?: Vehicle;
}

const StyledButton = styled(Button)({ minWidth: 150 });
const DataGrid = StyledDataGrid<VehicleTransfer>();

export default function TransferDetailsStep() {
	const { t } = useTranslation(['irp/supplements/transfer_vehicle', 'irp/supplements/add_vehicle', 'irp/supplements']);
	const navigate = useNavigate();
	const { openToast } = useToast();

	// Params
	const { supplementKey } = useTypedParams(TransferVehiclePaths.Details);

	// Services
	const supplementsService = useAPI(SupplementsService);
	const documentsService = useAPI(DocumentsService);
	const vehiclesService = useAPI(VehiclesService);
	const weightGroupsService = useAPI(WeightGroupsService);

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [rowsSaving, setRowsSaving] = useState<Record<string, boolean>>({});
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);
	const [addVehicleDialogOpen, setAddVehicleDialogOpen] = useState<boolean>(false);
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);
	const [removeDialogVehicle, setRemoveDialogVehicle] = useState<Vehicle | null>(null);
	const [deactivateReasons, setDeactivateReasons] = useState<LookupValue[]>([]);
	const [weightGroupTypes, setWeightGroupTypes] = useState<LookupValue[]>([]);

	// Validation
	const vehicleUpdateSchema = Yup.object().shape({
		deactivate: Yup.object()
			.shape({
				date: DateValidationSchema.default(undefined)
					.test('minDate', DateValidations.minDate(t))
					.test('futureDate', DateValidations.noFutureDate(t))
					.test(
						'required',
						t('data.validation.required', { ns: 'core' }),
						(v) => !!v && !!v.month && !!v.day && !!v.year,
					),
				reason: Yup.object()
					.default(undefined)
					.shape(LookupValueValidationSchema)
					.test('required', t('data.validation.required', { ns: 'core' }), (v) => !!v && !!v.code),
			})
			.test('transfer fee calc', transferFeeCalcTest({ t })),
		transferVehicle: Yup.object()
			.default(undefined)
			.test('required', t('data.validation.required', { ns: 'core' }), (v) => !!v)
			.shape({
				registration: Yup.object({
					feeCalculationDate: Yup.object().shape(LookupValueValidationSchema),
					leaseDate: DateValidationSchema.when('feeCalculationDate.code', {
						is: VehicleFeeCalculationDate.Lease,
						then: (s) =>
							s
								.required(t('data.validation.required', { ns: 'core' }))
								.test('minDate', DateValidations.minDate(t))
								.test('futureDate', DateValidations.noFutureDate(t)),
						otherwise: (s) => s.strip(),
					}),
					otherDate: DateValidationSchema.when('feeCalculationDate.code', {
						is: VehicleFeeCalculationDate.Other,
						then: (s) =>
							s
								.required(t('data.validation.required', { ns: 'core' }))
								.test('minDate', DateValidations.minDate(t))
								.test('futureDate', DateValidations.noFutureDate(t)),
						otherwise: (s) => s.strip(),
					}),
					firstOperatedDate: DateValidationSchema.when('feeCalculationDate.code', {
						is: VehicleFeeCalculationDate.FirstOperated,
						then: (s) =>
							s
								.required(t('data.validation.required', { ns: 'core' }))
								.test('minDate', DateValidations.minDate(t))
								.test('futureDate', DateValidations.noFutureDate(t)),
						otherwise: (s) => s.strip(),
					}),
				}),
				purchase: Yup.object().when('registration.feeCalculationDate.code', ([feeCalcCode], s) => {
					if (feeCalcCode !== VehicleFeeCalculationDate.Purchase) return s.strip();
					return s.shape({
						date: DateValidationSchema.required(t('data.validation.required', { ns: 'core' }))
							.test('minDate', DateValidations.minDate(t))
							.test('futureDate', DateValidations.noFutureDate(t)),
					});
				}),
			}),
	});

	// Form
	const { values, setValues, ...formik } = useFormik<VehicleTransfer[]>({
		initialValues: [],
		validationSchema: Yup.array().min(1).of(vehicleUpdateSchema),
		onSubmit: () => navigate(TransferVehiclePaths.Documentation.buildPath({ supplementKey })),
	});

	// Computed
	const isSaving = Object.values(rowsSaving).find((v) => v);
	const selectedVehicles = values.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const mapVehicleTransfer = (vehicles: Vehicle[]) =>
		vehicles.reduce((records: VehicleTransfer[], vehicle) => {
			if (vehicle.transferFromKey) return records;

			records.push({
				...vehicle,
				transferVehicle: vehicles.find(({ transferFromKey }) => transferFromKey === vehicle.key),
			});

			return records;
		}, []);

	const getVehicles = useCallback(() => {
		return supplementsService.listVehicles(supplementKey).then((vehiclesResp) => {
			setValues(mapVehicleTransfer(vehiclesResp));
		});
	}, [supplementKey, setValues, supplementsService]);

	const loadVehicles = async () => {
		setTableLoading(true);
		await getVehicles();
		setTableLoading(false);
	};

	useEffect(() => {
		Promise.all([
			getVehicles(),
			vehiclesService.getDeleteReasons().then(setDeactivateReasons),
			weightGroupsService.getTypes().then(setWeightGroupTypes),
		]).finally(() => setLoading(false));
	}, [getVehicles, documentsService, vehiclesService, weightGroupsService]);

	const updateVehicle = async (vehicleKey: string, field: string, value: unknown, transferVehicleKey?: string) => {
		const idx = values.findIndex((vehicle) => vehicle.key === vehicleKey);
		if (idx === -1) return;

		const errors = await formik.setFieldValue(`[${idx}].${field}`, value, true);
		const data: typeof values = setIn(values, `[${idx}].${field}`, value);

		if (errors && errors[idx] && Object.keys(errors[idx] || {}).length > 0) return;

		// Run after the formik state has been updated
		const vehicle = values[idx];
		try {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: true });

			const finalData = { ...data[idx], final: true };
			const updateFields = vehicleUpdateSchema.cast(finalData, {
				context: finalData,
				// Final cast to strip unknown fields, this is required to satisfy API minimum requirements
				stripUnknown: true,
			});

			if (!updateFields) return;

			await supplementsService.updateVehicle(
				supplementKey,
				transferVehicleKey || vehicle.key,
				transferVehicleKey
					? ({ ...updateFields.transferVehicle } as VehicleFields)
					: ({ deactivate: updateFields.deactivate } as VehicleFields),
			);
		} catch (e) {
			// Revert changes
			loadVehicles();
		} finally {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: false });
		}
	};

	const handleNext = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'transfer-details-form-errors',
				message: t('errors.vehicle.ready', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		// All purchase dates must share the same month and year
		const allDatesValid = values.every((val) => {
			if (!values[0].transferVehicle || !val.transferVehicle) return false;

			const firstFeeCalcDate = getFeeCalculationDate(values[0].transferVehicle);
			const nextFeeCalcDate = getFeeCalculationDate(val.transferVehicle);

			const firstDeactivateDate = values[0].deactivate?.date;
			const nextDeactivateDate = val.deactivate?.date;

			return (
				nextFeeCalcDate?.month === firstFeeCalcDate?.month &&
				nextFeeCalcDate?.year === firstFeeCalcDate?.year &&
				firstDeactivateDate?.month === nextDeactivateDate?.month &&
				firstDeactivateDate?.year === nextDeactivateDate?.year
			);
		});

		if (!allDatesValid) {
			return openToast({
				id: 'supplements/transfer-vehicle/all-dates',
				message: t('vehicles.errors.feeCalculationDate.allDates', { ns: 'irp/supplements' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleRemoveVehicleConfirm = async () => {
		if (!removeDialogVehicle) return undefined;

		return vehiclesService.remove(removeDialogVehicle).then(() => {
			loadVehicles();
			setRemoveDialogVehicle(null);

			openToast({
				id: `remove-vehicle-dialog:${removeDialogVehicle.key}`,
				message: t('dialogs.remove_vehicle.removed', { ns: 'irp/supplements' }),
				severity: 'success',
			});
		});
	};

	const columns: GridColDef<VehicleTransfer>[] = [
		{
			renderHeader: () => <DataGridColumnHeader label={t('vehicle.plate.number', { ns: 'data' })} />,
			field: 'plate.title',
			width: 80,
			valueGetter: ({ row }) => row.plate?.number,
		},

		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			width: 80,
			renderCell: ({ row }) => (
				<Box>
					<Typography fontFamily="Roboto Mono" variant="body2" noWrap>
						{row.unitNumber}
					</Typography>
					{row.transferVehicle?.vin && (
						<Typography fontFamily="Roboto Mono" variant="body2" fontWeight={700} noWrap>
							{row?.transferVehicle?.unitNumber}
						</Typography>
					)}
				</Box>
			),
		},

		{
			headerName: t('vehicle.vin', { ns: 'data' }),
			field: 'vin',
			minWidth: 115,
			flex: 1,
			renderCell: ({ row }) => (
				<Tooltip
					title={
						<Box>
							<div>{row.vin}</div>
							{row.transferVehicle?.vin && <div>{row.transferVehicle?.vin}</div>}
						</Box>
					}
				>
					<Box>
						<Typography fontFamily="Roboto Mono" variant="body2" noWrap>
							{row.vin}
						</Typography>
						{row.transferVehicle?.vin && (
							<Typography fontFamily="Roboto Mono" variant="body2" fontWeight={700} noWrap>
								{row?.transferVehicle?.vin}
							</Typography>
						)}
					</Box>
				</Tooltip>
			),
		},

		{
			headerName: t('vehicle.deactivateDate', { ns: 'data' }),
			field: 'deactivate.date',
			minWidth: 160,
			flex: 1,
			valueGetter: ({ row }) => {
				return row.deactivate?.date ? dayjsFromObject(row.deactivate.date) : null;
			},
			renderCell: ({ value, row }) => {
				return (
					<DatePicker
						value={value}
						onChange={(v: Dayjs | null) => {
							if (!v || !v.isValid()) return;

							const date = dayjsToDate(v);
							updateVehicle(row.key, 'deactivate.date', date);
						}}
						slotProps={{
							textField: {
								error: !!getIn(
									formik.errors[values.findIndex((vehicle) => vehicle.key === row.key)]?.deactivate,
									'date',
								),
								variant: 'standard',
							},
						}}
						minDate={MIN_DATE}
						disableFuture
					/>
				);
			},
		},

		{
			headerName: t('vehicle.deactivateReason', { ns: 'data' }),
			field: 'deactivate.reason',
			minWidth: 120,
			flex: 1,
			valueGetter: ({ row }) => row.deactivate?.reason?.displayName,
			renderCell: ({ row }) => {
				return (
					<FormControl fullWidth>
						<Select
							variant="standard"
							value={row.deactivate?.reason?.code || ''}
							error={
								!!getIn(formik.errors[values.findIndex((vehicle) => vehicle.key === row.key)]?.deactivate, 'reason')
							}
							onChange={(event) => {
								updateVehicle(
									row.key,
									'deactivate.reason',
									getLookupValueByCode(deactivateReasons, event.target.value),
								);
							}}
						>
							{deactivateReasons.map((reason) => (
								<MenuItem key={reason.id} value={reason.code}>
									{reason.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				);
			},
		},

		{
			renderHeader: () => <DataGridColumnHeader label={t('vehicle.registration.feeCalculationDate', { ns: 'data' })} />,
			headerName: t('vehicle.registration.feeCalculationDate', { ns: 'data' }),
			field: 'registration.feeCalculationDate',
			minWidth: 160,
			flex: 1,
			valueGetter: ({ row }) => {
				if (!row.transferVehicle) return null;

				const date = getFeeCalculationDate(row.transferVehicle);
				return date ? dayjsFromObject(date) : null;
			},
			renderCell: ({ value, row }) => {
				const feeCalculationType = row.transferVehicle?.registration?.feeCalculationDate?.code || '';
				const index = values.findIndex((vehicle) => vehicle.id === row.id);

				const error = getFeeCalcTooltipError(formik.errors[index]);

				const minDate = dayjsFromObject(row.deactivate?.date);
				const maxDate = dayjsFromObject(row.deactivate?.date)?.endOf('month');

				// No add vehicle attached, no fee calc date to set
				if (!row.transferVehicle) return null;

				return (
					<Tooltip title={error}>
						<Box>
							<DatePicker
								value={value}
								onChange={(v: Dayjs | null) => {
									if (!v || !v.isValid() || !row.purchase) return;
									const date = dayjsToDate(v);

									switch (feeCalculationType) {
										case VehicleFeeCalculationDate.Purchase:
											updateVehicle(row.key, 'transferVehicle.purchase.date', date, row.transferVehicle?.key);
											break;
										case VehicleFeeCalculationDate.FirstOperated:
											updateVehicle(
												row.key,
												'transferVehicle.registration.firstOperatedDate',
												date,
												row.transferVehicle?.key,
											);
											break;
										case VehicleFeeCalculationDate.Lease:
											updateVehicle(row.key, 'transferVehicle.registration.leaseDate', date, row.transferVehicle?.key);
											break;
										case VehicleFeeCalculationDate.Other:
											updateVehicle(row.key, 'transferVehicle.registration.otherDate', date, row.transferVehicle?.key);
											break;
										default:
											break;
									}
								}}
								slotProps={{
									textField: {
										variant: 'standard',
									},
								}}
								minDate={minDate}
								maxDate={maxDate}
								disableFuture
							/>
						</Box>
					</Tooltip>
				);
			},
		},

		{
			headerName: t('data.ready', { ns: 'core' }),
			field: 'ready',
			headerAlign: 'center',
			align: 'center',
			width: 65,
			valueGetter: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);
				return !formik.errors[index];
			},
			renderCell: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);

				// Loading state
				if (rowsSaving[row.key]) return <CircularProgress size={24} />;

				// Valid state
				if (!formik.errors[index]) return <CheckIcon color="success" />;

				// Error state
				return <span>&mdash;</span>;
			},
		},

		{
			headerName: t('data.actions', { ns: 'core' }),
			field: 'action',
			headerAlign: 'center',
			sortable: false,
			align: 'center',
			width: 70,
			renderCell: ({ row }) => {
				const index = values.findIndex((vehicle) => vehicle.id === row.id);
				const isReady = !formik.errors[index];
				let startLabel = t('buttons.start', { ns: 'core' });
				if (isReady) {
					startLabel = t('buttons.edit', { ns: 'core' });
				} else if (row?.deactivate?.date) {
					startLabel = t('buttons.resume', { ns: 'core' });
				}

				return (
					<ActionMenu
						options={[
							{ id: 'start', label: startLabel },
							{ id: 'view', label: t('buttons.view', { ns: 'core' }) },
							{ id: 'remove', label: t('buttons.remove', { ns: 'core' }) },
						]}
						onClick={({ id }) => {
							switch (id) {
								case 'start':
									navigate(
										TransferVehiclePaths.Vehicle.Delete.buildPath({
											supplementKey,
											deleteVehicleKey: row.key,
											addVehicleKey: values[index].transferVehicle?.key || VehicleNew,
										}),
									);
									break;
								case 'view':
									navigate(TransferVehiclePaths.Vehicle.View.buildPath({ supplementKey, deleteVehicleKey: row.key }));
									break;
								case 'remove':
									setRemoveDialogVehicle(row);
									break;
								default:
									break;
							}
						}}
					/>
				);
			},
		},
	];

	if (loading) return SupplementContentSkeleton;

	return (
		<>
			<form name="transferDetailsForm" noValidate>
				<Box display="flex" flexDirection="column" rowGap={2}>
					<Card>
						<CardContent>
							<Typography variant="h3">{t('title', { ns: 'irp/vehicles' })}</Typography>
							<Box mt={4} mb={2}>
								<DataGrid
									className="striped"
									columns={columns}
									rows={values}
									loading={tableLoading}
									rowHeight={60}
									disableRowSelectionOnClick
									checkboxSelection
									rowSelectionModel={selectedVehicleIds}
									onRowSelectionModelChange={setSelectedVehicleIds}
								/>
							</Box>
							<Box display="flex" columnGap={1}>
								<StyledButton variant="outlined" onClick={() => setAddVehicleDialogOpen(true)}>
									{t('dialogs.add_vehicle.title', { ns: 'irp/supplements' })}
								</StyledButton>
								{selectedVehicleIds.length > 0 && (
									<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
										{t('dialogs.edit_vehicle.title', { ns: 'irp/supplements', count: selectedVehicleIds.length })}
									</StyledButton>
								)}
							</Box>
						</CardContent>
					</Card>
					<SupplementStepFooter
						nextLabel={t('buttons.next', { ns: 'core' })}
						nextDisabled={isSaving}
						onNext={handleNext}
					/>
				</Box>
			</form>

			<AddVehicleDialog
				supplementKey={supplementKey}
				isOpen={addVehicleDialogOpen}
				setIsOpen={setAddVehicleDialogOpen}
				searchVehiclesRequest={{
					// CLEAR-1983: Only power units can be added to Add w Transfer
					weightGroupType: weightGroupTypes.find((type) => type.code === WeightGroupType.PowerUnit)?.id,
				}}
				onVehiclesAdded={() => {
					setAddVehicleDialogOpen(false);
					loadVehicles();
				}}
			/>

			<EditVehicleDialog
				vehicles={selectedVehicles}
				deactivateReasons={deactivateReasons}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>

			<RemoveVehicleDialog
				vehicle={removeDialogVehicle}
				isOpen={!!removeDialogVehicle}
				setIsOpen={() => {
					setRemoveDialogVehicle(null);
				}}
				onConfirm={handleRemoveVehicleConfirm}
			/>
		</>
	);
}
