import { Box, Card, CardContent, Typography } from '@mui/material';
import ClearFleetForm, { Field } from 'core/components/ClearFleetForm';
import { useAPI, useToast } from 'core/hooks';
import { dayjsFromObject } from 'core/services/intl';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import TransmittalService from 'modules/transmittals/api/TransmittalsService';
import TransmittalStepFooter from 'modules/transmittals/components/TransmittalStepFooter';
import TransmittalPaths from 'modules/transmittals/routes/paths';
import Transmittal from 'modules/transmittals/types/Transmittal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import Date, { DateValidations, DateValidationSchema, dayjsToDate } from 'types/Date';
import Program from 'types/Program';
import * as Yup from 'yup';

interface TransmittalStepStartProps {
	transmittal?: Transmittal;
	onGenerate?: () => void | Promise<void>;
}

interface TransmittalStepStartForm {
	period: {
		start: Date | null;
		end: Date | null;
	};
}

export default function TransmittalStepStart({ transmittal, onGenerate }: TransmittalStepStartProps) {
	// Hooks
	const { t } = useTranslation('transmittals');
	const { program } = useTypedParams(TransmittalPaths.Program);
	const navigate = useNavigate();
	const transmittalService = useAPI(TransmittalService);
	const { openToast } = useToast();

	const validationSchema = Yup.object({
		period: Yup.object({
			start: DateValidationSchema.required(t('data.validation.required', { ns: 'core' })).test(
				'futureDate',
				DateValidations.noFutureDate(t),
			),
			end: DateValidationSchema.required(t('data.validation.required', { ns: 'core' })).test(
				'futureDate',
				DateValidations.noFutureDate(t),
			),
		}),
	});

	const formik = useFormik<TransmittalStepStartForm>({
		initialValues: {
			period: {
				start: transmittal?.period.start || dayjsToDate(dayjs().subtract(1, 'month').startOf('month')),
				end: transmittal?.period.end || dayjsToDate(dayjs().subtract(1, 'month').endOf('month')),
			},
		},
		validationSchema,
		onSubmit: async (data) => {
			return Promise.resolve()
				.then(async () => {
					const req = validationSchema.cast(data);

					// Update existing transmittal
					if (transmittal) {
						// Update date range?
						await transmittalService.generate(transmittal.id, req.period);
						onGenerate?.();
						return transmittal.id;
					}

					// New transmittal, create based on date range
					return transmittalService
						.create({
							program: `PROGRAM_${program}` as Program,
							...req,
						})
						.then(({ id }) => id);
				})
				.then((id) => {
					// Navigate to next step
					// TODO pass newly created transmittal id or same transmittal id
					navigate(TransmittalPaths.Program.Edit.Confirm.buildPath({ program, id }));
				});
		},
	});

	const handleNext = async () => {
		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'transmittals/start',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	// Fields
	const fields: Field<TransmittalStepStartForm>[] = [
		{
			name: 'period.start',
			type: 'date',
			label: t('transmittal.period.start.label'),
			tooltip: t('transmittal.period.start.tooltip'),
			required: true,
			getValue: (v) => dayjsFromObject(v.period.start) || null,
			datePickerProps: {
				disableFuture: true,
			},
		},
		{
			name: 'period.end',
			type: 'date',
			label: t('transmittal.period.end.label'),
			tooltip: t('transmittal.period.end.tooltip'),
			required: true,
			getValue: (v) => dayjsFromObject(v.period.end) || null,
			datePickerProps: {
				disableFuture: true,
			},
		},
	];

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<Card>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', rowGap: 2 }}>
					<Typography variant="h3">{t('program.title', { program })}</Typography>
					<Typography variant="subtitle1">{t('steps.start.subtitle')}</Typography>

					<ClearFleetForm fields={fields} form={formik} loading={formik.isSubmitting} />
				</CardContent>
			</Card>

			<TransmittalStepFooter
				previousPath={TransmittalPaths.buildPath({})}
				nextLabel={t('buttons.start', { ns: 'core' })}
				onNext={handleNext}
			/>
		</Box>
	);
}
