import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import SupplementPageContainer from 'modules/irp/modules/supplements/components/SupplementPageContainer';
import AddVehicleSteps from 'modules/irp/modules/supplements/modules/add_vehicle/components/AddSteps';
import AddVehicleContainer from 'modules/irp/modules/supplements/modules/add_vehicle/components/AddVehicleContainer';
import SupplementBlocker from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { lazy, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import AddDetailsStep from './AddDetailsStep';
import AddVehiclePaths from './paths';

const AddDocumentsStep = lazy(() => import('./AddDocumentsStep'));
const AddVerifyStep = lazy(() => import('./AddVerifyStep'));
const AddSubmitStep = lazy(() => import('./AddSubmitStep'));

const AddVehicleOptionsRoute = lazy(() => import('./AddVehicleOptions'));
const AddVehicleInfoRoute = lazy(() => import('./AddVehicleInfo'));
const AddVehicleWeightGroupRoute = lazy(() => import('./AddVehicleWeightGroup'));
const AddVehicleRegistrationRoute = lazy(() => import('./AddVehicleRegistration'));

function AddVehicleIndex() {
	const { supplementKey } = useTypedParams(AddVehiclePaths);
	return <Redirect to={AddVehiclePaths.Details.buildPath({ supplementKey })} replace />;
}

function WithBlocker({ children }: PropsWithChildren) {
	const { t } = useTranslation('irp/supplements/add_vehicle');
	return (
		<SupplementBlocker
			basePath={AddVehiclePaths}
			title={t('dialogs.close.title', { ns: 'irp/supplements/add_vehicle' })}
		>
			{children}
		</SupplementBlocker>
	);
}

// Route component mappings
const AddVehicleRoutes = [
	{
		path: AddVehiclePaths.path,
		element: (
			<CanAccess resource="irp.supplements.add_vehicle" action={Actions.READ} denied={<NoPermission />}>
				<SupplementPageContainer
					breadcrumbs={['irp/supplements/add_vehicle', 'irp/supplements', 'irp/vehicles']}
					stepRoutes={[
						AddVehiclePaths.Details.path,
						AddVehiclePaths.Documentation.path,
						AddVehiclePaths.Verify.path,
						AddVehiclePaths.Submit.path,
					]}
					steps={<AddVehicleSteps />}
				>
					<Outlet />
				</SupplementPageContainer>
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements/add_vehicle' }),
		},
		children: [
			// Index route
			{
				path: AddVehiclePaths.path,
				index: true,
				element: <AddVehicleIndex />,
			},

			// Steps
			{
				path: AddVehiclePaths.Details.path,
				element: (
					<WithBlocker>
						<AddDetailsStep />
					</WithBlocker>
				),
			},
			{
				path: AddVehiclePaths.Documentation.path,
				element: (
					<WithBlocker>
						<AddDocumentsStep />
					</WithBlocker>
				),
			},
			{
				path: AddVehiclePaths.Verify.path,
				element: (
					<WithBlocker>
						<AddVerifyStep />
					</WithBlocker>
				),
			},
			{
				path: AddVehiclePaths.Submit.path,
				element: (
					<WithBlocker>
						<AddSubmitStep />
					</WithBlocker>
				),
			},

			// Vehicle add/edit
			{
				path: AddVehiclePaths.Vehicle.path,
				element: (
					<AddVehicleContainer>
						<Outlet />
					</AddVehicleContainer>
				),
				handle: {
					crumb: ({ t }) => t('vehicle.title', { ns: 'irp/supplements/add_vehicle' }),
				},
				children: [
					// Index route
					{
						path: '',
						index: true,
						element: <AddVehicleOptionsRoute />,
					},
					{
						path: AddVehiclePaths.Vehicle.Info.path,
						element: <AddVehicleInfoRoute />,
					},
					{
						path: AddVehiclePaths.Vehicle.WeightGroup.path,
						element: <AddVehicleWeightGroupRoute />,
					},
					{
						path: AddVehiclePaths.Vehicle.Registration.path,
						element: <AddVehicleRegistrationRoute />,
					} as Route<typeof AddVehiclePaths.Vehicle.Registration>,
				],
			} as Route<typeof AddVehiclePaths.Vehicle>,
		],
	} as Route<typeof AddVehiclePaths>,
];

export default AddVehicleRoutes;
