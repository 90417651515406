import { DateFormat } from 'core/services/intl';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { Namespace, TFunction } from 'i18next';
import * as Yup from 'yup';

dayjs.extend(isSameOrAfter);

export default interface Date {
	year: number;
	month: number;
	day: number;
}

export interface DateRange {
	endDate: Date;
	startDate: Date;
}

export const DateValidationSchema = Yup.object().shape({
	year: Yup.number().defined(),
	month: Yup.number().defined(),
	day: Yup.number().defined(),
});

export const MIN_DATE = dayjs({ month: 0, day: 1, year: 2000 });
export const MAX_DATE = dayjs({ month: 0, day: 1, year: new Date().getFullYear() + 1 });

export const DateValidations = {
	noFutureDate:
		(t: TFunction<Namespace>) =>
		(data: Partial<Date> | undefined, { createError, path }: Yup.TestContext) =>
			data && dayjs({ ...data, month: (data.month || 0) - 1 }).isAfter(dayjs())
				? createError({ path, message: t(`data.validation.datepicker.disableFuture`, { ns: 'core' }) })
				: true,
	minDate:
		(t: TFunction<Namespace>, minDate?: dayjs.Dayjs) =>
		(data: Partial<Date> | undefined, { createError, path }: Yup.TestContext) =>
			data && dayjs({ ...data, month: (data.month || 0) - 1 }).isBefore(minDate || MIN_DATE)
				? createError({
						path,
						message: t(`data.validation.datepicker.minDate`, {
							ns: 'core',
							minDate: (minDate || MIN_DATE).format(DateFormat),
						}),
					})
				: true,
};

export const dayjsToDate = (date?: dayjs.Dayjs): Date | null => {
	if (!date || !date.isValid()) return null;

	return {
		year: date.year(),
		month: date.month() + 1,
		day: date.date(),
	};
};

export const isOnOrAfter = (d1: dayjs.Dayjs, d2: dayjs.Dayjs) => {
	return d2.isSameOrAfter(d1, 'day');
};
