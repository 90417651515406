import { numberFormat } from 'core/services/intl';
import Tracking from 'types/Tracking';

export default interface Document {
	id: string;
	name: string;
	contentType: string;
	size: number;
	type: DocumentType;
	tracking: Tracking;
	tags?: Record<string, string>;
}

export enum DocumentType {
	Form2290 = 'form2290',
	Title = 'title',
	Other = 'other',
	SelfCertification = 'selfCertification',
	ClaimForRefund = 'claimForRefund',
}

export const MaxDocumentSize = 25 * 1024 * 1024; // 25 MB

export function documentSize(size: number, showDecimals = true): string {
	if (size < 1024) {
		return `${numberFormat(size)} B`;
	}
	if (size < 1024 * 1024) {
		return `${numberFormat(size / 1024, showDecimals ? 2 : 0)} KB`;
	}

	return `${numberFormat(size / (1024 * 1024), showDecimals ? 2 : 0)} MB`;
}
