import { Check, Search } from '@mui/icons-material';
import { CircularProgress, Input, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import StyledDataGrid, { DataGridColumnHeader } from 'core/components/DataGrid';
import { useAPI } from 'core/hooks';
import { setIn, useFormik } from 'formik';
import { DocumentType } from 'modules/documents/types/Document';
import DocumentsService from 'modules/irp/modules/supplements/api/DocumentsService';
import SupplementsService from 'modules/irp/modules/supplements/api/SupplementsService';
import EditVehicleDocumentsDialog from 'modules/irp/modules/supplements/components/dialogs/EditVehicleDocumentsDialog';
import { vehicleStatus, VehicleStatus } from 'modules/irp/modules/supplements/modules/renewal/types/VehicleStatus';
import SupplementPaths from 'modules/irp/modules/supplements/routes/paths';
import Vehicle, { VehicleFields } from 'modules/irp/modules/vehicles/types/Vehicle';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import LookupValue, { LookupValueID, LookupValueValidationSchema } from 'types/LookupValue';
import * as Yup from 'yup';

const DataGrid = StyledDataGrid<Vehicle>();

export enum VehicleDocumentationTableType {
	All = 'all',
	Active = 'active',
	Delete = 'delete',
	Transfer = 'transfer',
}

export interface VehicleDocumentationTableProps {
	documentTypes: DocumentType[];
	tableType?: VehicleDocumentationTableType;
	validator?: Dispatch<SetStateAction<boolean>> | null;
	readonly?: true;
}

const StyledButton = styled(Button)({ minWidth: 150 });

function NoRowsOverlay() {
	const { t } = useTranslation(['irp/supplements/new_account', 'irp/supplements']);
	return (
		<Box display="flex" justifyContent="center" alignItems="center" height="100%">
			<Typography variant="subtitle1">{t('irp/supplements:documentation.no_rows')}</Typography>
		</Box>
	);
}

export default function VehicleDocumentationTable({
	documentTypes,
	tableType = VehicleDocumentationTableType.All,
	validator = null,
	readonly,
}: VehicleDocumentationTableProps) {
	// Hooks
	const { t } = useTranslation(['irp/supplements']);
	const { supplementKey } = useTypedParams(SupplementPaths.Supplement);
	const supplementsService = useAPI(SupplementsService);
	const documentsService = useAPI(DocumentsService);

	const documentsSchema = documentTypes.reduce(
		(acc, type) => {
			acc[type] = Yup.object().shape(LookupValueValidationSchema).required();
			return acc;
		},
		{} as Record<DocumentType, Yup.ObjectSchema<LookupValueID>>,
	);

	const vehicleUpdateSchema = Yup.object().shape({
		documents: Yup.object(documentsSchema),
		registration: Yup.object({
			temporaryAuthorityNeeded: Yup.boolean().nullable(),
		}).optional(),
	});

	// Form
	const { values, setValues, validateForm, ...formik } = useFormik<Vehicle[]>({
		initialValues: [],
		validationSchema: Yup.array().min(1).of(vehicleUpdateSchema),
		onSubmit: async () => {
			// do nothing - api updated on every change
		},
	});

	// State
	const [loading, setLoading] = useState<boolean>(true);
	const [documentStatuses, setDocumentStatuses] = useState<LookupValue[]>([]);
	const [rowsSaving, setRowsSaving] = useState<Record<string, boolean>>({});
	const [editVehicleDialogOpen, setEditVehicleDialogOpen] = useState<boolean>(false);
	const [selectedVehicleIds, setSelectedVehicleIds] = useState<GridRowSelectionModel>([]);
	const [searchValue, setSearchValue] = useState<string>('');

	// Computed
	const selectedVehicles = values.filter((vehicle) => selectedVehicleIds.includes(vehicle.id));

	const getVehicles = useCallback(() => {
		return supplementsService.listVehicles(supplementKey).then((res) => {
			setValues(res);
		});
	}, [supplementKey, setValues, supplementsService]);

	const loadVehicles = async () => {
		setLoading(true);
		await getVehicles();
		setLoading(false);
	};

	useEffect(() => {
		setLoading(true);
		Promise.all([
			documentsService.getDocumentStatuses().then((res) => setDocumentStatuses(res)),
			getVehicles(),
		]).finally(() => setLoading(false));
	}, [supplementKey, documentsService, getVehicles]);

	const getDocStatusByCode = (code: string) => documentStatuses?.find((s) => s.code === code);

	const updateVehicle = async (vehicleKey: string, field: string, value: unknown) => {
		const idx = values.findIndex((vehicle) => vehicle.key === vehicleKey);
		if (idx === -1) return;

		const errors = await formik.setFieldValue(`[${idx}].${field}`, value, true);
		const data: typeof values = setIn(values, `[${idx}].${field}`, value);

		// Invalid, skip
		if (errors && errors[idx] && Object.keys(errors[idx] || {}).length > 0) return;

		// Run after the formik state has been updated
		const vehicle = values[idx];
		try {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: true });

			const finalData = { ...data[idx], final: true };
			const updateFields = vehicleUpdateSchema.cast(finalData, {
				context: finalData,
				stripUnknown: true,
			});
			if (!updateFields) return;

			await supplementsService.updateVehicle(supplementKey, vehicle.key, updateFields as VehicleFields);
		} catch (e) {
			// Revert changes
			loadVehicles();
		} finally {
			setRowsSaving({ ...rowsSaving, [vehicle.key]: false });
		}
	};

	const baseColumns: GridColDef<Vehicle>[] = [
		{ headerName: t('vehicle.vin', { ns: 'data' }), field: 'vin', minWidth: 180, flex: 1 },
		{
			headerName: t('vehicle.unitNumber', { ns: 'data' }),
			field: 'unitNumber',
			minWidth: 90,
			flex: 1,
		},
		{
			headerName: t('vehicle.title.number', { ns: 'data' }),
			field: 'title.number',
			minWidth: 90,
			flex: 1,
			valueGetter: ({ row }) => row.title.number,
		},
	];

	const tempAuthCol: GridColDef<Vehicle> = {
		field: 'registration.temporaryAuthorityNeeded',
		width: 100,
		renderHeader: () => (
			<DataGridColumnHeader
				label={t('verify.temporaryAuthority.abbreviation')}
				tooltip={t('verify.tooltips.tempAuth')}
			/>
		),
		align: readonly ? 'left' : 'center',
		renderCell: ({ row }) => {
			if (readonly) return row.registration.temporaryAuthorityNeeded ? <Check color="success" /> : `\u2014`;

			return (
				<Checkbox
					name="temporaryAuthorityNeeded"
					checked={row.registration.temporaryAuthorityNeeded}
					onChange={(event: ChangeEvent<HTMLInputElement>) => {
						if (!row.registration) return;
						updateVehicle(row.key, 'registration.temporaryAuthorityNeeded', event.target.checked);
					}}
				/>
			);
		},
	};

	const plateReturnColumn: GridColDef<Vehicle> = {
		headerName: t('vehicle.plate.return', { ns: 'data' }),
		field: 'plate.return',
		minWidth: 90,
		flex: 1,
		valueGetter: ({ row }) => row.plate?.return?.displayName || '\u2014',
	};

	const deleteReasonColumn: GridColDef<Vehicle> = {
		headerName: t('vehicle.deactivateReason', { ns: 'data' }),
		field: 'deactivate.reason',
		minWidth: 90,
		flex: 1,
		valueGetter: ({ row }) => row.deactivate?.reason?.displayName || '\u2014',
	};

	const documentColumns = documentTypes.map((documentType) => ({
		headerName: t(`vehicle.documents.${documentType}.title`, { ns: 'data' }),
		field: `documents.${documentType}`,
		minWidth: 145,
		flex: 1,
		valueGetter: ({ row }: { row: Vehicle }) => row.documents?.[documentType]?.displayName,
		renderCell: ({ row }: GridRenderCellParams<Vehicle>) => {
			if (readonly) return row.documents?.[documentType]?.displayName;

			return (
				<FormControl fullWidth>
					<Select
						variant="standard"
						value={documentStatuses ? row.documents?.[documentType]?.code || '' : ''}
						onChange={(event: SelectChangeEvent<string>) => {
							if (!row.documents) return;
							updateVehicle(row.key, `documents.${documentType}`, getDocStatusByCode(event.target.value));
						}}
					>
						{documentStatuses.map((status) => (
							<MenuItem key={status.id} value={status.code}>
								{status.displayName}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);
		},
	}));

	const readyColumn: GridColDef<Vehicle> = {
		headerName: t('data.ready', { ns: 'core' }),
		field: 'ready',
		headerAlign: 'center',
		align: 'center',
		width: 70,
		valueGetter: ({ row }) => {
			const index = values.findIndex((vehicle) => vehicle.id === row.id);
			return !formik.errors[index];
		},
		renderCell: ({ row }) => {
			const index = values.findIndex((vehicle) => vehicle.id === row.id);

			// Loading state
			if (rowsSaving[row.key]) return <CircularProgress size={24} />;

			// Valid state
			if (!formik.errors[index]) return <Check color="success" />;

			// Error state
			return <span>&mdash;</span>;
		},
	};

	const columns: GridColDef<Vehicle>[] = (() => {
		switch (tableType) {
			case VehicleDocumentationTableType.Active:
				return [...baseColumns, tempAuthCol, ...documentColumns, readyColumn];
			case VehicleDocumentationTableType.Delete:
				return [...baseColumns, plateReturnColumn, ...documentColumns, readyColumn];
			case VehicleDocumentationTableType.Transfer:
				return [...baseColumns, deleteReasonColumn, ...documentColumns, readyColumn];
			default:
				return [...baseColumns, tempAuthCol, ...documentColumns, readyColumn];
		}
	})();

	// Computed
	const filteredRows = useMemo(() => {
		const vehicles = (() => {
			switch (tableType) {
				case VehicleDocumentationTableType.Active:
					return values.filter((v) => vehicleStatus(v) !== VehicleStatus.Delete);

				case VehicleDocumentationTableType.Delete: // intentional fallthrough
				case VehicleDocumentationTableType.Transfer:
					return values.filter((v) => vehicleStatus(v) === VehicleStatus.Delete);

				default:
					return values;
			}
		})();

		return (
			vehicles.filter((vehicle) => {
				const { vin, title, unitNumber, documents, deactivate, plate } = vehicle;
				const search = searchValue.toLowerCase();
				return (
					vin.toLowerCase().includes(search) ||
					title?.number?.toLowerCase().includes(search) ||
					unitNumber.toLowerCase().includes(search) ||
					(tableType === VehicleDocumentationTableType.Transfer &&
						deactivate?.reason?.displayName.toLowerCase().includes(search)) ||
					(tableType === VehicleDocumentationTableType.Delete &&
						plate?.return?.displayName.toLowerCase().includes(search)) ||
					(documentTypes.includes(DocumentType.Form2290) &&
						documents?.form2290?.displayName?.toLowerCase().includes(search)) ||
					(documentTypes.includes(DocumentType.Title) &&
						documents?.title?.displayName?.toLowerCase().includes(search)) ||
					(documentTypes.includes(DocumentType.Other) &&
						documents?.other?.displayName?.toLowerCase().includes(search)) ||
					(documentTypes.includes(DocumentType.SelfCertification) &&
						documents?.other?.displayName?.toLowerCase().includes(search)) ||
					(documentTypes.includes(DocumentType.ClaimForRefund) &&
						documents?.other?.displayName?.toLowerCase().includes(search))
				);
			}) || []
		);
	}, [documentTypes, searchValue, tableType, values]);

	useEffect(() => {
		// prevent parent component navigation if form is invalid
		if (!validator) return;
		validateForm(filteredRows).then((errors) => {
			validator(!Object.keys(errors).length);
		});
	}, [filteredRows, validator, validateForm]);

	return (
		<>
			<Box display="flex" flexDirection="column" rowGap={2}>
				<Box>
					<Box display="flex" justifyContent="flex-end" mb={3}>
						<Input
							startAdornment={<Search color="primary" />}
							placeholder={`${t('buttons.search', { ns: 'core' })}...`}
							value={searchValue}
							onChange={(e) => setSearchValue(e.currentTarget.value)}
						/>
					</Box>

					<DataGrid
						className="striped"
						columns={columns}
						rows={filteredRows}
						loading={loading}
						slots={{ noRowsOverlay: NoRowsOverlay }}
						checkboxSelection={!readonly}
						rowSelectionModel={selectedVehicleIds}
						onRowSelectionModelChange={setSelectedVehicleIds}
						disableRowSelectionOnClick
					/>
				</Box>
				{!readonly && (
					<Box display="flex" columnGap={1} mt={4}>
						{selectedVehicleIds.length > 0 && (
							<StyledButton variant="outlined" onClick={() => setEditVehicleDialogOpen(true)}>
								{t('irp/supplements:dialogs.edit_vehicle.title', { count: selectedVehicleIds.length })}
							</StyledButton>
						)}
					</Box>
				)}
			</Box>

			<EditVehicleDocumentsDialog
				vehicles={selectedVehicles}
				documentStatuses={documentStatuses}
				isOpen={editVehicleDialogOpen}
				setIsOpen={setEditVehicleDialogOpen}
				documentTypes={documentTypes}
				excludeTA={
					tableType === VehicleDocumentationTableType.Delete || tableType === VehicleDocumentationTableType.Transfer
				}
				onVehiclesEdited={() => {
					setEditVehicleDialogOpen(false);
					setSelectedVehicleIds([]);
					loadVehicles();
				}}
			/>
		</>
	);
}
