import { CanAccess } from 'core/components';
import NoPermission from 'core/components/NoPermission';
import Redirect from 'core/components/Redirect';
import { Actions } from 'core/types/permissions';
import { Route } from 'core/types/routing';
import AddFleetRoutes from 'modules/irp/modules/supplements/modules/add_fleet/routes';
import AddVehicleRoutes from 'modules/irp/modules/supplements/modules/add_vehicle/routes';
import DeleteVehicleRoutes from 'modules/irp/modules/supplements/modules/delete_vehicle/routes';
import EditFleetRoutes from 'modules/irp/modules/supplements/modules/edit_fleet/routes';
import EditVehicleRoutes from 'modules/irp/modules/supplements/modules/edit_vehicle/routes/index';
import NewAccountRoutes from 'modules/irp/modules/supplements/modules/new_account/routes';
import RenewalRoutes from 'modules/irp/modules/supplements/modules/renewal/routes';
import TransferVehicleRoutes from 'modules/irp/modules/supplements/modules/transfer_vehicle/routes/index';
import WeightGroupChangeRoutes from 'modules/irp/modules/supplements/modules/weight_group_change/routes';
import { SupplementProvider, useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { SupplementTypePaths } from 'modules/irp/modules/supplements/routes/paths';
import RazorPaths from 'modules/razor/paths';
import { lazy, PropsWithChildren, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { SupplementType } from 'types/Supplement';
import SupplementRouter from './SupplementRouter';
import SupplementPaths from './paths';

const SupplementDocuments = lazy(() => import('./SupplementDocuments'));

// Route component mappings
const SupplementsRoutes = [
	{
		path: SupplementPaths.path,
		element: (
			<CanAccess resource="irp.supplements" action={Actions.READ} denied={<NoPermission />}>
				<Outlet />
			</CanAccess>
		),
		handle: {
			crumb: ({ t }) => t('title', { ns: 'irp/supplements' }),
		},
		children: [
			// Index route
			{
				path: SupplementPaths.path,
				index: true,
				element: <Redirect to={RazorPaths.Manage.Supplements.buildPath({})} replace />,
			} as Route<typeof SupplementPaths>,

			// Supplement path
			{
				path: SupplementPaths.Supplement.path,
				element: (
					<SupplementProviderFromPath>
						<SupplementModuleGuard>
							<Outlet />
						</SupplementModuleGuard>
					</SupplementProviderFromPath>
				),
				children: [
					// Redirect based on supplement type
					{
						path: SupplementPaths.Supplement.path,
						index: true,
						element: <SupplementRouter />,
					},

					// Supplement documents
					{
						path: SupplementPaths.Supplement.Documents.path,
						element: <SupplementDocuments />,
						handle: {
							crumb: ({ t }) => t('documentation.step', { ns: 'irp/supplements' }),
						},
					},

					// Sub-module routes
					...AddVehicleRoutes,
					...EditVehicleRoutes,
					...TransferVehicleRoutes,
					...DeleteVehicleRoutes,
					...WeightGroupChangeRoutes,
					...AddFleetRoutes,
					...EditFleetRoutes,
					...NewAccountRoutes,
					...RenewalRoutes,
				],
			},
		],
	} as Route<typeof SupplementPaths>,
];
export default SupplementsRoutes;

// Supplement provider from path params
function SupplementProviderFromPath({ children }: PropsWithChildren) {
	const params = useTypedParams(SupplementPaths.Supplement);
	return <SupplementProvider supplementKey={params.supplementKey}>{children}</SupplementProvider>;
}

// Supplement module guard ensures we're in the correct module for the supplement
function SupplementModuleGuard({ children }: PropsWithChildren) {
	const context = useSupplement();
	const location = useLocation();
	const navigate = useNavigate();

	// Make sure we're in the correct module
	useEffect(() => {
		if (!context || !context.supplement) return;
		const { supplement } = context;

		// Get expected module for the supplement type code
		const expected = SupplementTypePaths[supplement.type.code as SupplementType];
		if (!expected) {
			// Supplement is not supported, redirect
			navigate(
				RazorPaths.Manage.Supplements.buildPath(
					{},
					{
						supplementKeyId: supplement.key,
						accountKeyId: supplement.accountKey,
					},
				),
			);
		}

		// Expected path does not match actual path, redirect
		const correctPath = expected.buildPath({ supplementKey: supplement.key });
		const documentationPath = SupplementPaths.Supplement.Documents.buildPath({ supplementKey: supplement.key });
		if (!location.pathname.startsWith(correctPath) && !location.pathname.startsWith(documentationPath)) {
			navigate(expected.buildPath({ supplementKey: supplement.key }), {
				replace: true,

				// Bypass any supplement blockers
				state: { bypass: true },
			});
		}
	}, [location.pathname, navigate, context]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}
