import ApiService from 'core/services/api';
import LookupValue from 'types/LookupValue';

// TODO move to Documents module at some point
export default class DocumentsService extends ApiService {
	constructor() {
		const baseUrl = process.env.REACT_APP_CLEARFLEET_API as string;
		super(`${baseUrl}/v1/documents`);
	}

	async getDocumentStatuses(): Promise<LookupValue[]> {
		const path = '/config/statuses';
		const { statuses } = await this.GET<{ statuses: LookupValue[] }>(path);

		return statuses;
	}
}
