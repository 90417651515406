import { Box } from '@mui/material';
import { useAPI, useToast } from 'core/hooks';
import FleetsService from 'modules/irp/modules/fleets/api/FleetsService';
import FleetForm, { FleetFormProps } from 'modules/irp/modules/fleets/components/FleetForm';
import SupplementStepFooter from 'modules/irp/modules/supplements/components/SupplementStepFooter';
import { useSupplement } from 'modules/irp/modules/supplements/providers/SupplementProvider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import AddFleetPaths from './paths';

export default function AddFleetDetailsStep() {
	const { openToast } = useToast();

	// Hooks
	const { t } = useTranslation(['irp/supplements/add_fleet', 'irp/fleets']);
	const navigate = useNavigate();
	const { supplementKey } = useTypedParams(AddFleetPaths.Details);
	const { supplement, reload: reloadSupplement } = useSupplement();

	// Services
	const fleetsService = useAPI(FleetsService);

	// State
	const [formik, setFormik] = useState<Parameters<FleetFormProps['onFormik']>[0] | null>(null);

	// Computed
	const fleet = supplement?.fleet;

	const handleNext = async () => {
		if (!formik) return undefined;

		const errors = await formik.validateForm();
		if (Object.keys(errors).length > 0) {
			openToast({
				id: 'irp/fleets/details',
				message: t('data.validation.form_incomplete', { ns: 'core' }),
				severity: 'error',
			});
		}

		return formik.submitForm();
	};

	const handleSubmit: FleetFormProps['onSubmit'] = async (data, actions) => {
		if (!fleet) return undefined;

		return fleetsService
			.update(fleet.key, {
				...data,
				...actions,
			})
			.then(reloadSupplement)
			.then(() => {
				openToast({
					id: `irp/supplements/add-fleet/details`,
					message: t('toasts.updated', { ns: 'irp/fleets' }),
					severity: 'success',
				});
			})
			.then(() =>
				navigate(
					data.trailerOnly
						? AddFleetPaths.WeightGroups.buildPath({ supplementKey })
						: AddFleetPaths.Details.Jurisdictions.buildPath({ supplementKey }),
				),
			);
	};

	return (
		<Box display="flex" flexDirection="column" rowGap={2}>
			<FleetForm fleet={fleet || null} onFormik={setFormik} onSubmit={handleSubmit} editEffectiveDate editMileage />

			<SupplementStepFooter nextLabel={t('buttons.next', { ns: 'core' })} onNext={handleNext} />
		</Box>
	);
}
